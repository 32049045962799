const Home = () => {
    return ( 
        <div className="home">
            <h2>This is the home page!</h2> <br />
            <p>Hi! I'm Natalia, aka MMDDKK <br />I am 17 years old and have been learning how to code for almost 8 years now! <br /> I am from Brazil! (simbora BRASILLL) <br /> This website was made using ReactJS! First time using it, so I hope it turns out well :3</p> <br />
            <p>There was going to be a blog too but I can't figure out how to introduce passwords or tokens in github pages</p>
        </div>
     );
}
 
export default Home;